import gsap from "gsap";

//______VARIABLES______//

const
isMobile = window.innerWidth<900,
body = document.querySelector("body"),
header = document.querySelector("header"),
burger = document.getElementById("burger"),
burger_open = document.getElementById("burger-open"),
burger_close = document.getElementById("burger-close"),
navigation = document.getElementById("navigation"),
links = document.querySelectorAll("nav li"),
columns = document.querySelectorAll(".columns span");

let menu_state = false;
let lastScrollTop = window.scrollY,
offset = 200;


//______MENU______//

const open_menu = ()=>{
    body.style.overflow = "clip";
    gsap.timeline()
    .to(columns,{
        height:"100%",
        stagger:0.1,
    },"0")
    .to(navigation,{
        autoAlpha:1
    },"0")
    .to(links,{
        x:0,
        stagger:0.1
    },"0")
    .to(burger_open,{
        rotate:360,
        autoAlpha:0,
    },"0")
    .to(burger_close,{
        rotate:360,
        autoAlpha:1
    },"0");
};
const close_menu = () =>{
    body.style.overflow = "inherit";
    gsap.timeline()
    .to(links,{
        x:"-150%",
        stagger:0.1
    },"0")
    .to(columns,{
        height:0,
        stagger:0.1,
    },"0")
    .to(navigation,{
        autoAlpha:0
    },"0")
    .to(burger_open,{
        rotate:0,
        autoAlpha:1,
    },"0")
    .to(burger_close,{
        rotate:0,
        autoAlpha:0
    },"0");
};
burger.addEventListener("click",()=>{
    if(menu_state){
        close_menu();
    }else{
        open_menu();
    }
    menu_state = !menu_state;
});

//Show-hide fixed menu
window.addEventListener('scroll',()=>{
    const scrollTopPosition = window.scrollY;
    if(scrollTopPosition == 0){ //Menu appear at top
        header.classList.remove("scrollView");
        gsap.to(header,{
            y:0,
            delay:0.5
          });
          return;
    }
    if (scrollTopPosition > lastScrollTop && scrollTopPosition > offset) { //Menu dissapear on scroll down
      gsap.to(header,{
        y:isMobile? -90 : -180,
      })
    } else if (scrollTopPosition>offset && scrollTopPosition < lastScrollTop) { //Menu appear on scroll up
      header.classList.add("scrollView");
      gsap.to(header,{
        y:0,
      })
    }
    lastScrollTop = scrollTopPosition <= 0 ? 0 : scrollTopPosition;
  },
  false,
);
